<template>
  <v-form ref="form" lazy-validation>
    <v-row>
      <v-col cols="12" md="8">
        <BasePhonepicker
          v-model="userData.phone"
          :country="userData.country"
          :label="$t('onboarding.personalDataForm.phone')"
        />
      </v-col>
      <v-col cols="12" md="4">
        <datepicker
          v-model="userData.birthdate"
          disabled
          :label="$t('onboarding.personalDataForm.dateOfBirth')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="userData.national_id"
          filled
          disabled
          :label="$t('onboarding.personalDataForm.nationalIdNumber')"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <BaseMultiselect
          v-model="userData.doc_country"
          filled
          :items="registrationHints.country"
          disabled
          :loading="loadingRegistrationHints && !onboarding"
          :rules="[(v) => !!v || true || $t('errors.documentIssuingCountryRequired')]"
          :label="$t('onboarding.personalDataForm.documentIssuingCountry')"
          :multiple="false"
          :tooltip="true"
          multi-select-data-type="country"
          required
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="userData.doc_num"
          filled
          required
          disabled
          :rules="[(v) => !!v || true || $t('errors.fieldRequired')]"
          :label="$t('onboarding.personalDataForm.documentNumber')"
        />
      </v-col>
      <v-col cols="12" md="4">
        <datepicker
          v-model="userData.doc_date"
          disabled
          :rules="docDateRules"
          :label="$t('onboarding.personalDataForm.validTill')"
        />
      </v-col>
    </v-row>

    <v-row v-if="show">
      <v-col cols="12" md="4" style="padding-top:0">
        <v-btn
          :disabled="!approved"
          tile
          large
          elevation="0"
          :loading="loading"
          @click="dialog = true"
          v-text="$t('common.reverify')"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <BaseMultiselect
          v-model="userData.country"
          filled
          :disabled="!onboarding"
          :items="registrationHints.country"
          :loading="loadingRegistrationHints"
          :rules="[(v) => !!v || $t('errors.fieldRequired')]"
          :label="$t('onboarding.personalDataForm.countryOfResidency')"
          :multiple="false"
          multi-select-data-type="country"
        />
      </v-col>
      <v-col cols="6">
        <BaseMultiselect
          v-model="userData.tax_country"
          filled
          :items="registrationHints.tax_country"
          :loading="loadingRegistrationHints"
          :rules="[(v) => !!v || $t('errors.fieldRequired')]"
          :label="$t('onboarding.personalDataForm.countryOfTaxResidency')"
          :multiple="false"
          multi-select-data-type="country"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="userData.address"
          filled
          required
          :rules="[(v) => !!v || $t('errors.fieldRequired')]"
          :label="$t('onboarding.personalDataForm.address')"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-select
          v-model="userData.activity"
          filled
          :items="economicActivities"
          :label="$t('onboarding.personalDataForm.fieldOfEconomicalActivity')"
          item-text="name"
          item-value="code"
          :rules="[
            (v) => !!v || $t('onboarding.personalDataForm.fieldOfEconomicalActivityRequired'),
          ]"
          required
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        {{ $t('onboarding.personalDataForm.areYouPoliticallyExposedPerson') }}
        <v-tooltip max-width="500" left>
          <template #activator="{ on, attrs }">
            <v-icon
              color="primary"
              small
              v-bind="attrs"
              v-on="on"
            >
              mdi-information-outline
            </v-icon>
          </template>

          <strong>{{ $t('onboarding.personalDataForm.politicallyExposedPerson') }}</strong>
          <br>
          {{ $t('onboarding.personalDataForm.politicallyExposedPersonDescription') }}
          <br>
          <strong>{{ $t('onboarding.personalDataForm.familyMebmerOfPep') }}</strong>
          <br>
          {{ $t('onboarding.personalDataForm.familyMebmerOfPepDescription') }}
          <br>
          <strong>{{ $t('onboarding.personalDataForm.closeAssociateOfPep') }}</strong>
          <br>
          {{ $t('onboarding.personalDataForm.closeAssociateOfPepDescription') }}
        </v-tooltip>
        <v-radio-group
          v-model="userData.is_exposed"
          required
          :rules="[(v) => v != null || $t('errors.fieldRequired')]"
          row
        >
          <v-radio :label="$t('onboarding.personalDataForm.yes')" :value="true"/>
          <v-radio :label="$t('onboarding.personalDataForm.no')" :value="false"/>
        </v-radio-group>
      </v-col>
    </v-row>

    <div v-if="onboarding">
      <v-row>
        <v-col cols="12">
          {{ $t('onboarding.personalDataForm.areYouActualBeneficiary') }}
          <v-tooltip max-width="300" left>
            <template #activator="{ on, attrs }">
              <v-icon
                color="primary"
                small
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <strong>{{ $t('onboarding.personalDataForm.beneficialOwner') }}</strong>
            <br> {{ $t('onboarding.personalDataForm.beneficialOwnerDescription') }}
          </v-tooltip>
          <v-radio-group
            v-model="userData.beneficiary"
            required
            :rules="[(v) => !!v || $t('onboarding.personalDataForm.beneficiaryRequired')]"
            row
          >
            <v-radio :label="$t('onboarding.personalDataForm.yes')" :value="true"/>
            <v-radio :label="$t('onboarding.personalDataForm.no')" :value="false"/>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="userData.consent1"
            required
            :rules="[(v) => !!v || $t('errors.agreeToContinue')]"
            :label="$t('onboarding.personalDataForm.notUseTheCreditForIllegalPurposes')"
          />

          <v-checkbox
            v-model="userData.consent2"
            required
            :rules="[(v) => !!v || $t('errors.agreeToContinue')]"
            :label="$t('onboarding.personalDataForm.notUseTheCreditForIllegalPaymentsToCompany')"
          />

          <v-checkbox
            v-model="userData.consent3"
            required
            :rules="[(v) => !!v || $t('errors.agreeToContinue')]"
            :label="$t('onboarding.personalDataForm.confirmProvidedAccurateData')"
          />

          <v-checkbox
            v-model="userData.consent4"
            required
            :rules="[(v) => !!v || $t('errors.agreeToContinue')]"
            :label="$t('onboarding.personalDataForm.confirmInformCompanyOnAnyChanges')"
          />
        </v-col>
      </v-row>
    </div>

    <v-checkbox
      v-if="!onboarding"
      v-model="userData.consent5"
      required
      :rules="[(v) => !!v || $t('errors.agreeToContinue')]"
      :label="$t('onboarding.personalDataForm.confirmProvidedAccurateData')"
    />

    <v-btn
      tile
      large
      elevation="0"
      :loading="loading"
      dark
      @click="submit"
      v-text="$t('common.save')"
    />
    <v-dialog v-model="dialog" max-width="33rem">
      <v-card>
        <v-card-title v-text="$t('onboarding.banner.reverification')"/>
        <v-card-text class="pt-4" v-text="$t('onboarding.banner.youAreAboutToRestart')"/>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog = false" v-text="$t('startInvesting.cancel')"/>
          <v-btn
            color="green"
            text
            :loading="loading"
            @click="reverify"
            v-text="$t('onboarding.banner.reverify')"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: "PersonalDataForm",
  props: {
    onboarding: { type: Boolean, default: false },
  },
  emits: ["success"],
  data() {
    return {
      loading: false,
      loadingRegistrationHints: true,
      registrationHints: { country: [], tax_country: [], activity: [] },
      valid: true,
      userData: {
        address: null, activity: null, beneficiary: null, doc_country: null,
        consent1: null, consent2: null, consent3: null, consent4: null, consent5: null,
        country: null, tax_country: null,
      },
      docDateRules: [
        (v) => !!v || true || this.$t('errors.fieldRequired'),
      ],
      dialog: false,
    }
  },
  computed: {
    user() {
      return this.$auth.user()
    },
    economicActivities() {
      return this.registrationHints.activity.map(code => {
        return { code, name: this.$t(`economicActivities.${code}`) }
      })
    },
    show() {
      if (this.$route.name === 'Onboarding') {
        return false
      }
      if (!this.$auth.user()) {
        return false
      }
      return true
    },
    approved() {
      return this.$auth.user().verification === 'approved'
    },
  },
  watch: {
    "user":  {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue) {
          // We expect some fields to be updated from other components, but these ones we want to “own”:
          const ownedFields = [ 'phone', 'address', 'activity', 'is_exposed', 'country', 'tax_country' ]
          if (!this.onboarding) {
            ownedFields.push('national_id', 'doc_num', 'doc_date', 'doc_country')
          }
          const keptValues = {}
          for (const field of ownedFields) {
            if (oldValue && newValue[field] === oldValue[field]) {
              keptValues[field] = this.userData[field]
            }
          }
          this.userData = { ...this.userData, ...newValue, ...keptValues }
        }
      },
    },
  },
  mounted() {
    this.fetchRegistrationHints()
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$http
          .patch(`/auth/users/me/`, {
            phone:       this.userData.phone,
            address:     this.userData.address,
            activity:    this.userData.activity,
            is_exposed:  this.userData.is_exposed,
            tax_country: this.userData.tax_country,
            update_last_data_update: true,
          })
          .then(() => this.$auth.fetch())
          .then(() => {
            this.loading = false
            this.$emit("next")
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    fetchRegistrationHints() {
      this.loadingRegistrationHints = true
      this.axios
        .get(`/auth/registration_hints/`)
        .then((response) => {
          this.loadingRegistrationHints = false
          this.registrationHints = response.data
        })
        .catch(() => {
          /* TODO so what do we do if this fails? */
          this.loadingRegistrationHints = false
        })
    },
    reverify() {
      this.loading = true
      axios.post('/auth/users/reverify/', {
      })
        .then(() => this.$auth.fetch())
        .then(() => {
          this.loading = false
          this.dialog = false
          this.$router.push('/register')
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
})
</script>
