<template>
  <Container :title="$t(`settingsPage.edit${ isCompanyAccount ? 'Representative' : 'Personal' }Info`)">
    <PersonalDataForm @next="done"/>
  </Container>
</template>

<script>
import PersonalDataForm from "../components/PersonalDataForm"

import Vue from 'vue'

export default Vue.extend({
  name: "PersonalInfoEdit",
  components: {
    PersonalDataForm,
  },
  computed: {
    isCompanyAccount() {
      return (!!this.$store.state.account
           && !!this.$store.state.account.company)
    },
  },
  methods: {
    done() {
      this.$router.push('/account/personal')
    },
  },
})
</script>
